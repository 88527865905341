import axios from "axios";
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useState } from "react";
// import { usePathname } from 'next/router';
import logo from '../../assets/images/table-tennis-logo.png' // table-tennis-logo.png
import { getApiURL } from "../../common/utils/domainUtil";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Breadcrumbs, Chip, emphasize, styled } from "@mui/material/";
import { Home } from "@mui/icons-material";

// Breadcrum code
const StyledBreadcrumb = styled(Chip)(({theme}) => {
    const backgroundColor =
        theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
})

const TransactionDetail = () => {

    // Check login
    // const navigate = useNavigate();
    // const token = localStorage.getItem("jwtToken");
    // if (token == null || token == undefined) navigate("/login");

    const params = useParams();

    const [dataTransaction, setDataTransaction] = useState('');
    const [qrCode, setQrCode] = useState('');

    const apiPayOSTransReport = 'public/payos/transaction-report/';
    const apiURLPayOSTransReport = getApiURL(apiPayOSTransReport);

    useEffect(() => {
        const fetchDetail = async () => {
            try {
                const response = await axios.get(`${apiURLPayOSTransReport}${params.id}`);
                setDataTransaction(response.data);
                setQrCode(response.data.qrCode);
            } catch (error) {
                console.error('Error fetching detail:', error);
            }
        };

        fetchDetail();
    }, [params.id]);

    return <>
        <section className="right-content w-100">
            <div className="card shadow border-0 w-100 flex-row p-4">
                <h5 className="mb-0">Transaction Detail</h5>
                <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
                    <StyledBreadcrumb
                        component="a"
                        href="#"
                        label="Dashboard"
                        icon={<Home fontSize="small"/>}
                    />
                    <StyledBreadcrumb
                        component="a"
                        href="#"
                        label="Transaction"
                    />
                    <StyledBreadcrumb
                        label="Detail"
                    />
                </Breadcrumbs>
            </div>

            <div className="row dashboardBoxWrapperRow">
                <div className="col-md-8">
                    <div className="dashboardBoxWrapper d-flex">

                    </div>
                </div>

                <div className="col-md-4 pl-0">
                    <div className="box">

                    </div>
                </div>
            </div>

            <div className="card p-3 mt-4">
                <h3 className="hd">Chi tiết giao dịch</h3>
                <div className="row p-4">
                    <table className="table table-bordered v-align">
                        <thead className="thead-dark">
                            <tr>
                                <th style={{width:'100px'}}>NỀN TẢNG</th>
                                <th style={{width:'100px'}}>SẢN PHẨM</th>
                                <th>SỐ TIỀN CẦN THANH TOÁN</th>
                                <th>TRẠNG THÁI</th>
                                <th>LINK THANH TOÁN</th>
                                <th>NỘI DUNG</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>{dataTransaction.platform}</td>
                                <td>
                                    <div className="d-flex align-items-center productBox">
                                        <div className="imgWrapper">
                                            <div className="img">
                                                <img src="/upload/images/avatar-qr-code.png" className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>{dataTransaction.amount}</td>
                                <td>
                                    {dataTransaction.status === 'PENDING' ? (
                                        <span>Chờ thanh toán</span>
                                    ) : dataTransaction.status === 'CANCELLED' ? (
                                        <span>Hủy bỏ</span>
                                    ) : dataTransaction.status === 'SUCCESS' ? (
                                        <span>Thành công</span>
                                    ) : (
                                        <span>{dataTransaction.status}</span>
                                    )}
                                </td>
                                <td>
                                    {dataTransaction.status === 'PENDING' ? (
                                        <Link to={`${dataTransaction.checkoutUrl}`} target="_blank">
                                            {dataTransaction.platform}
                                        </Link>
                                    ) : (
                                        <p></p>
                                    )}
                                </td>
                                <td>{dataTransaction.description}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {dataTransaction.status === 'PENDING' ? (
                    <div>
                        <h3 className="hd">Mã QR thanh toán</h3>

                        <div className="row pl-3">
                            <QRCodeCanvas
                                value={qrCode}
                                size={128}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"L"}
                                includeMargin={false}
                                imageSettings={{
                                    src: logo,
                                    x: undefined,
                                    y: undefined,
                                    height: 24,
                                    width: 24,
                                    excavate: true,
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <p></p>
                )}

                <h3 className="hd mt-3">Line ID</h3>

                <div className="row pl-3">
                    {dataTransaction.lineIdSeq}
                </div>
            </div>
        </section>
    </>
}

export default TransactionDetail;
