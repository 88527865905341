// ----------------------------------------------------------------------

import { pxToRem } from "../../utils/theme";

export default function Input(theme) {
  return {
    MuiTextField: {
      // defaultProps: { size: "small" },

    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(14)
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& svg": { color: theme.palette.text.disabled }
          }
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: theme.palette.text.secondary
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {

        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          "&:hover": {
            backgroundColor: theme.palette.grey[500_16]
          },
          "&.Mui-focused": {
            backgroundColor: theme.palette.action.focus
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.action.disabledBackground
          }
        },
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiOutlinedInput: {
      // defaultProps: { size: "small" },
      styleOverrides: {
        root: {
          fontWeight: 500,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grey[500_32]
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.disabledBackground
            }
          }
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: theme.palette.text.secondary
          }
        }
      }
    }
  };
}
