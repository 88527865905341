import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import RegisterNodePopup from "components/line/RegisterNodePopup";
import UnregistNodePopup from "components/line/UnregistNodePopup";
import RemoveLineNodePopup from "components/line/RemoveLineNodePopup";
import { LineRow } from "./LineRow";
import { useNavigate } from "react-router-dom";
import { useHandleRegisterLineProgressNode } from "hooks/useHandleRegisterLineProgressNode";
import { useHandleUnregisterLineProgressNode } from "hooks/useHandleUnregisterLineProgressNode";
import { useHandleRemoveLineProgressNode } from "hooks/useHandleRemoveLineProgressNode";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.dark,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const LineTable = ({ rows = [] }) => {
  const navigate = useNavigate();

  const { showModalPopupAdd, setShowModalPopupAdd, registerNewNode, handleRegister, currentNode } =
    useHandleRegisterLineProgressNode({
      onComplete: () => {
        navigate(`/line/${currentNode.id}`);
      },
    });

  const { showModalPopupUnregist, setShowModalPopupUnregist, unregistNode, handleUnregist } = // , unregistNode, handleUnregist, currentNode
    useHandleUnregisterLineProgressNode({
      onComplete: () => {
        console.log(currentNode)
        navigate(`/line/${currentNode.id}`);
      },
    });

  const { showModalPopupRemoveLine, setShowModalPopupRemoveLine, removeLineNode, handleRemoveLine } =
    useHandleRemoveLineProgressNode({
      onComplete: () => {
        navigate(`/line/${currentNode.id}`);
      },
    });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell width={"200px"}>
              <Typography variant="h6" sx={{ color: "primary.main" }}>
                Sản phẩm
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="h6" sx={{ color: "primary.main" }}>
                Thông tin
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((row) => (
            <LineRow
              key={row.bladeUnitID}
              row={row}
              registerNewNode={registerNewNode}
              unregistNode={unregistNode}
              removeLineNode={removeLineNode}
            />
          ))}
          {!rows ||
            (rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Không có sản phẩm
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <RegisterNodePopup
        onClose={() => setShowModalPopupAdd(false)}
        open={showModalPopupAdd}
        handleRegister={() => {
          handleRegister();
        }}
      />

      <UnregistNodePopup
        onClose={() => setShowModalPopupUnregist(false)}
        open={showModalPopupUnregist}
        handleUnregist={() => {
          handleUnregist();
        }}
      />

      <RemoveLineNodePopup
        onClose={() => setShowModalPopupRemoveLine(false)}
        open={showModalPopupRemoveLine}
        handleRemoveLine={() => {
          handleRemoveLine();
        }}
      />
    </TableContainer>
  );
};

export { LineTable };
