import { createBrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import Blade from "../pages/Blade";
import BladeCreate from "../pages/BladeCreate";
import BladeDetail from "../pages/BladeDetail";
import Brand from "../pages/Brand";
import BrandCreate from "../pages/BrandCreate";
import BrandDetail from "../pages/BrandDetail";
import Dashboard from "../pages/Dashboard";
import Line from "../pages/Line";
import LineCreate from "../pages/LineCreate";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import ProductDetails from "../pages/ProductDetails";
import ProductUpload from "../pages/ProductUpload";
import TransactionReport from "../pages/TransactionReport";
import TransactionDetail from "../pages/TransactionDetail";
import AdminLayout from "../components/AdminLayout";
import UserProfile from "../pages/UserProfile";
import LineDetailPage from "../pages/LineDetail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AdminLayout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/product/details",
        element: <ProductDetails />,
      },
      {
        path: "/product/upload",
        element: <ProductUpload />,
      },
      {
        path: "/brand-list",
        element: <Brand />,
      },
      {
        path: "/brand-create",
        element: <BrandCreate />,
      },
      {
        path: "/brand-detail/:brandCD",
        element: <BrandDetail />,
      },
      {
        path: "/blade-list",
        element: <Blade />,
      },
      {
        path: "/blade-create",
        element: <BladeCreate />,
      },
      {
        path: "/blade-detail/:bladeCD",
        element: <BladeDetail />,
      },
      {
        path: "/line-list",
        element: <Line />,
      },
      {
        path: "/line/:lineID",
        element: <LineDetailPage />,
      },
      {
        path: "/line-create",
        element: <LineCreate />,
      },
      {
        path: "/transaction-report",
        element: <TransactionReport />,
      },
      {
        path: "/transaction-detail/:id",
        element: <TransactionDetail />,
      },
      {
        path: "/profile",
        element: <UserProfile />,
      },
    ],
  },

  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
]);

export const AppRouter = () => {
  return <RouterProvider router={router}></RouterProvider>;
};

export * from "./paths";
