import { AuthProvider } from "./AuthProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const queryClient = new QueryClient();
const AppProvider = ({ children }) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={CLIENT_ID}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <AuthProvider>{children}</AuthProvider>
          </LocalizationProvider>
        </GoogleOAuthProvider>
        <ToastContainer position="bottom-right" autoClose={3000} />
      </QueryClientProvider>
    </>
  );
};
export default AppProvider;
