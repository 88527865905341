import { Autocomplete as MAutoComplete, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

const RhfAutocomplete = ({
    disabled,
    name,
    loading,
    options = [],
    keyValue = "value",
    label,
    getOptionLabel = (option) => option?.label,
    onChange = () => { },
    disablePortal = false,
    multiple = false,
    defaultValue = {},
    ...rest
}) => {
    const { control } = useFormContext();
    const theme = useTheme();
    const downXL = useMediaQuery(theme.breakpoints.down("xl"));

    // const size = downXL ? "small" : "medium";

    const getValueObject = useCallback(
        (value) => {
            if (keyValue) {
                if (multiple) {
                    if (!value) return [];
                    // if (Array.isArray(value)) {
                    //   const result = [...options].filter((item) => value.some((v) => v == item[keyValue]));
                    //   return result;
                    // } else {
                    //   return [];
                    // }

                    // Kiểm tra nếu value là một chuỗi hoặc một mảng
                    if (typeof value === "string") {
                        try {
                            // Cố gắng chuyển đổi chuỗi thành đối tượng JSON
                            const parsedValue = JSON.parse(value);

                            // Kiểm tra nếu parsedValue là một mảng
                            if (Array.isArray(parsedValue)) {
                                // Sử dụng some trên parsedValue (đối tượng mảng)
                                const result = [...options].filter((item) => parsedValue.some((v) => v == item[keyValue]));
                                return result;
                            }
                        } catch (error) {
                            return [];
                        }
                    } else if (Array.isArray(value)) {
                        // Nếu value là một mảng, sử dụng some trực tiếp
                        const result = [...options].filter((item) => value.some((v) => v == item[keyValue]));
                        return result;
                    } else {
                        // Xử lý trường hợp khác nếu value không phải là chuỗi hoặc mảng
                        return [];
                    }
                } else {
                    if (!value) return null;
                    const result = [...options].find((item) => item[keyValue] == value);
                    return result;
                }
            }
            return value;
        },
        [options]
    );

    if (multiple) {
        return (
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange: onFieldChange, value }, fieldState: { error } }) => {
                    let autocompleteValue = getValueObject(value);

                    return (
                        <MAutoComplete
                            defaultValue={defaultValue}
                            multiple={multiple}
                            disabled={disabled}
                            // size={size}
                            disablePortal={disablePortal}
                            noOptionsText="Không có dữ liệu"
                            loading={loading}
                            options={options ?? []}
                            value={autocompleteValue}
                            onChange={(event, newValue) => {
                                if (keyValue) {
                                    onFieldChange(newValue.map((item) => item[keyValue]));
                                    // onFieldChange(newValue?.[keyValue]);
                                } else {
                                    onFieldChange(newValue);
                                }
                                onChange(newValue);
                            }}
                            fullWidth
                            getOptionLabel={getOptionLabel}
                            renderInput={(params) => {
                                if (!params.inputProps.value && autocompleteValue) {
                                    params.inputProps.value = getOptionLabel(autocompleteValue);
                                }
                                return <TextField {...params} label={label} error={Boolean(error)} helperText={error?.message} />;
                            }}
                            {...rest}
                        />
                    );
                }}
            />
        );
    }
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange: onFieldChange, value }, fieldState: { error } }) => {
                let autocompleteValue = getValueObject(value);

                return (
                    <MAutoComplete
                        disabled={disabled}
                        // size={size}
                        disablePortal={disablePortal}
                        noOptionsText="Không có dữ liệu"
                        loading={loading}
                        options={options ?? []}
                        value={autocompleteValue}
                        onChange={(event, newValue) => {
                            if (keyValue) {
                                onFieldChange(newValue?.[keyValue]);
                            } else {
                                onFieldChange(newValue);
                            }
                            onChange(newValue);
                        }}
                        fullWidth
                        getOptionLabel={getOptionLabel}
                        renderInput={(params) => {
                            if (!params.inputProps.value && autocompleteValue) {
                                params.inputProps.value = getOptionLabel(autocompleteValue);
                            }
                            return <TextField {...params} label={label} error={Boolean(error)} helperText={error?.message} />;
                        }}
                        {...rest}
                    />
                );
            }}
        />
    );
};
export { RhfAutocomplete as RhfAutocomplete };