import { CardContent, CardHeader, FormHelperText, LinearProgress, Card, Paper } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import UploadMultiFile from "../upload/UploadMultiFile";
import { useCallback, useState } from "react";
// import { saveFilesToServer } from "@/utils";

const RhfUploadMultipleFile = ({ name, label, shouldPreview = false }) => {
  const { control, getValues, setValue } = useFormContext();
  const [isLoadingUpfile, setIsLoadingUpfile] = useState(false);

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const olds = getValues(name) ?? [];
      setIsLoadingUpfile(true);
      // const data = await saveFilesToServer(acceptedFiles);
      console.log(acceptedFiles);
      if (shouldPreview) {
        // acceptedFiles = acceptedFiles.map((file) => {
        //   return { ...file, preview: URL.createObjectURL(file) };
        // });

        acceptedFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }

      setValue(name, [...olds, ...acceptedFiles]);
      setIsLoadingUpfile(false);
    },
    [setValue]
  );

  const handleRemoveAll = () => {
    setValue(name, []);
  };

  const handleRemove = (file) => {
    const filteredItems = getValues(name).filter((_file) => _file.path !== file.path);
    setValue(name, filteredItems);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Paper variant="outlined" sx={{
            // borderStyle: "dashed",
          }}>
            <CardHeader
              title={label}
            />
            <UploadMultiFile
              maxSize={31457280}
              accept="*"
              files={field.value}
              onDrop={handleDrop}
              onRemove={handleRemove}
              onRemoveAll={handleRemoveAll}
              error={Boolean(error)}
              showPreview={shouldPreview}
            />
            {isLoadingUpfile && <LinearProgress />}
            {error?.message && (
              <FormHelperText error sx={{ px: 2 }}>
                {error.message}
              </FormHelperText>
            )}
          </Paper>
        );
      }}
    />
  );
};
export { RhfUploadMultipleFile as RhfUploadMultipleFile };
