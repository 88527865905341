import { Add, CallReceived, Check, Close, Edit, PlusOne, Save, TrendingFlat, Unpublished } from "@mui/icons-material";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import UploadMultipleFileDialog from "components/upload/UploadMultiFileDialog";
import { replaceImagesForLineProgress, updateCommentForLineProgress } from "api/line";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const LineActivity = ({ datas = [] }) => {
  return (
    <Card>
      <CardHeader title="Activity" />
      <CardContent>
        <Timeline
          sx={{
            "& .MuiTimelineItem-missingOppositeContent:before": {
              display: "none",
            },
          }}
        >
          {datas?.map((item, index) => (
            <LineActivityItem key={item.lpID} item={item} isLast={index === datas.length - 1} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
};

const LineActivityItem = ({ item, isLast }) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            p: 0,
          }}
        >
          <Avatar
            //   src={transitions.avatar}
            sx={{ width: 32, height: 32, boxShadow: (theme) => theme.customShadows.z8 }}
          />
        </TimelineDot>
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <LineProgressInfo item={item} />
      </TimelineContent>
    </TimelineItem>
  );
};

const LineProgressInfo = ({ item }) => {
  const { user } = useAuth();
  const currentUserEmail = user?.email;
  const { name, userEmail, lpID, images = [], comment } = item;
  const canEdit = true || userEmail === currentUserEmail;

  const [edit, setEdit] = useState(false);
  const [cmt, setCmt] = useState(comment ?? "");
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const queryClient = useQueryClient();
  const cancelEdit = () => {
    setEdit(false);
    setCmt(comment);
  };

  const handleSaveImage = async (imageUrls) => {
    setOpenUploadFile(!openUploadFile);
    const newImages = imageUrls.map((url) => ({ url, objId: lpID.toString() }));
    await replaceImagesForLineProgress(lpID, [...images, ...newImages]);
    toast.success("Upload success");
    queryClient.invalidateQueries({ queryKey: ["line"] });
  };

  const handleSaveComment = async () => {
    try {
      await updateCommentForLineProgress(lpID, cmt);
      setEdit(false);
      toast.success("comment success");
      queryClient.invalidateQueries({ queryKey: ["line"] });
    } catch (error) {}
  };

  if (!name)
    return (
      <Stack direction={"column"} sx={{ width: "100%", height: "100%", pt: 1 }} alignItems={"flex-start"}>
        <Chip
          label={
            <Typography variant="subtitle1" sx={{ color: "warning.main" }}>
              Not yet
            </Typography>
          }
          deleteIcon={<PlusOne />}
          sx={{ color: "warning.main" }}
          icon={<Unpublished fontSize="small" sx={{ color: "warning.main" }} color="primary" />}
        ></Chip>
      </Stack>
    );
  return (
    <Box sx={{ pt: 1 }}>
      <Stack direction={"column"} spacing={0.5} alignItems={"flex-start"}>
        <Stack direction={"row"} justifyContent={"space-between"} alignSelf={"stretch"} spacing={1}>
          <Typography variant="subtitle1">{name + " "}</Typography>

          {canEdit && (
            <IconButton size="small" onClick={() => setEdit(!edit)}>
              <Edit fontSize="small" />
            </IconButton>
          )}
        </Stack>
        <Typography variant="caption" sx={{ color: "grey.500" }} fontWeight={500}>
          {"11/11/1111"}
        </Typography>
        {edit && canEdit ? (
          <Stack direction={"row"} sx={{ width: "100%" }} spacing={1}>
            <TextField fullWidth multiline onChange={(e) => setCmt(e.target.value)} value={cmt} />
            <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
              <Button variant="outlined" onClick={cancelEdit}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSaveComment}>
                Save
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {cmt}{" "}
          </Typography>
        )}
        {/* {fileList?.map((item) => (
        <AttachFile key={item?.urlFile} item={item} />
      ))} */}

        <Grid container direction="row" spacing={1} alignItems={"center"}>
          {images?.map((item, index) => (
            <Grid item key={index} width={"120px"}>
              <Card variant="outlined">
                <img src={item.url} alt="blade" style={{ width: "100%" }} />
              </Card>
            </Grid>
          ))}

          {canEdit && edit && (
            <Grid item width={"120px"} height={"120px"}>
              <Card
                elevation={0}
                variant="outlined"
                sx={{
                  p: 1,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderStyle: "dashed",
                  //   backgroundColor: isRegisted ? "grey.200" : "white",
                  flexDirection: "column",
                }}
              >
                <IconButton
                  // disabled={isRegisted}
                  onClick={() => setOpenUploadFile(true)}
                >
                  <Add />
                </IconButton>
              </Card>
            </Grid>
          )}
        </Grid>
      </Stack>
      <UploadMultipleFileDialog
        open={openUploadFile}
        onClose={() => setOpenUploadFile(false)}
        onSave={handleSaveImage}
      />
    </Box>
  );
};

export default LineActivity;
