import { jwtDecode } from "jwt-decode";
import { api } from "../api";

const isValidToken = (accessToken) => {
  if (!accessToken || accessToken === "undefined" || accessToken === "null") {
    return false;
  }
  try {
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    console.log("decoded", decoded, currentTime);

    return decoded.exp > currentTime;
  } catch (error) {
    return false;
  }
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    delete api.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
