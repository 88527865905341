import { createContext, useEffect, useState } from "react";
import { api } from "../api";
import { isValidToken } from "../utils/jwt";
import { verifyGoogleToken } from "../api/auth";

const AuthContext = createContext({
  user: null,
  isAuthenticated: false,
  login: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("jwtToken");
    if (token && isValidToken(token)) {
      //TODO: get current user
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      setIsAuthenticated(true);
    }
  }, []);
  const login = async (emailOrUsername, password) => {
    try {
      const res = await api.post("public/signin", {
        emailOrUsername: emailOrUsername,
        password: password,
      });

      localStorage.setItem("jwtToken", res.data.token);
      const { email, username, firstname, lastname } = res.data;
      const user = { email, username, firstname, lastname };
      setUser(user);
      console.log("SET USER", { email, username, firstname, lastname });

      setIsAuthenticated(true);
      localStorage.setItem("emailOrUsername", emailOrUsername);
      localStorage.setItem("email", user.email);
      localStorage.setItem("username", user.username);
      localStorage.setItem("firstname", user.firstname);
      localStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const loginWithGoogle = async (credential) => {
    try {
      console.log(credential);
      const res = await verifyGoogleToken(credential);
      localStorage.setItem("jwtToken", res.data.token);
      const { email, username, firstname, lastname } = res.data;
      const user = { email, username, firstname, lastname };
      setUser(user);
      console.log("SET USER", { email, username, firstname, lastname });

      setIsAuthenticated(true);

      localStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const logout = async () => {
    localStorage.removeItem("jwtToken");
    setUser(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        login,
        logout,
        loginWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
