import { useParams } from "react-router-dom";
import LineActivity from "./LineActivity";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getLineDetail } from "../../api/line";
import { Suspense } from "react";
import LineDetail from "./LineDetail";
import { Card, CardContent, CardHeader, Container, Stack } from "@mui/material";
import { LineProcess } from "components/line";
import RegisterNodePopup from "components/line/RegisterNodePopup";
import { useHandleRegisterLineProgressNode } from "hooks/useHandleRegisterLineProgressNode";
import { useHandleUnregisterLineProgressNode } from "hooks/useHandleUnregisterLineProgressNode";
import UnregistNodePopup from "components/line/UnregistNodePopup";

const LineDetailPage = () => {
  let { lineID } = useParams();

  const { data: line } = useQuery({
    queryKey: ["line", lineID],
    queryFn: async () => {
      return getLineDetail(lineID);
    },
    suspense: true,
  });

  const lineData = line?.data;
  const lineProgresses = line?.data?.listProgress || [];
  const queryClient = useQueryClient();

  const { showModalPopupAdd, setShowModalPopupAdd, registerNewNode, handleRegister, currentNode } =
    useHandleRegisterLineProgressNode({
      onComplete: () => {
        queryClient.invalidateQueries({ queryKey: ["line"] });
      },
    });

    const { showModalPopupUnregist, setShowModalPopupUnregist, unregistNode, handleUnregist } = // , unregistNode, handleUnregist, currentNode
    useHandleUnregisterLineProgressNode({
      onComplete: () => {
        // navigate(`/line/${currentNode.bladeUnitId}`);
        queryClient.invalidateQueries({ queryKey: ["line"] });
      },
    });


  return (
    <Container maxWidth="xl">
      <Stack spacing={4} className="right-content w-100">
        {lineData && <LineDetail line={lineData} />}
        <Card>
          <CardHeader title="Line Process" />
          <CardContent>
            <LineProcess data={lineData} fullWidth={true} registerNewNode={registerNewNode} unregistNode={unregistNode} />
          </CardContent>
        </Card>
        <LineActivity datas={lineProgresses} />
      </Stack>

      <RegisterNodePopup
        onClose={() => setShowModalPopupAdd(false)}
        open={showModalPopupAdd}
        handleRegister={() => {
          handleRegister();
        }}
        // lineID={lineID}
        // token={token}
        // email={email}
      />
      <UnregistNodePopup
        onClose={() => setShowModalPopupUnregist(false)}
        open={showModalPopupUnregist}
        handleUnregist={() => {
          handleUnregist();
        }}
      />
    </Container>
  );
};

const withSuspense = (Component) => (props) => {
  return (
    <Suspense fallback={"loading!"}>
      <Component {...props} />
    </Suspense>
  );
};
export default withSuspense(LineDetailPage);
