import { useFormContext, Controller } from "react-hook-form";
import { MenuItem, TextField, useMediaQuery, useTheme } from "@mui/material";

const RhfSelect = ({ handleChange = () => {}, name, label, options, ...rest }) => {
  const { control } = useFormContext();
  const theme = useTheme();
  const downXL = useMediaQuery(theme.breakpoints.down("xl"));

  const size = downXL ? "small" : "medium";
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <TextField
              {...field}
              label={label}
              select
              fullWidth
              //   SelectProps={{ native: true }}
              error={!!error}
              helperText={error?.message}
              {...rest}
              onChange={ (e)=> (handleChange(e), field.onChange(e))}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </>
        );
      }}
    />
  );
};

export default RhfSelect;
