import { useState } from "react";
import useAuth from "./useAuth";
import { api } from "api";
import { register } from "../api/line";
import { toast } from "react-toastify";

export const useHandleRegisterLineProgressNode = ({ onComplete = () => {} }) => {
  const { user } = useAuth();
  const [showModalPopupAdd, setShowModalPopupAdd] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const registerNewNode = (data) => {
    setShowModalPopupAdd(true);
    setCurrentNode(data);
  };

  const handleRegister = async (event) => {
    try {
      const responsePromise = register({
        email: user.email,
        bladeUnitID: currentNode.id,
        seq: currentNode.seq,
        token: "ABCXYZ",
      });

      const response = await toast.promise(responsePromise, {
        pending: "Promise is pending",
        success: "Đăng ký thành công",
        error: "Đăng ký thất bại",
      });

      onComplete({
        response,
        currentNode,
      });

      setShowModalPopupAdd(false);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    showModalPopupAdd,
    setShowModalPopupAdd,
    registerNewNode,
    handleRegister,
    currentNode,
    setCurrentNode,
  };
};
