import axios from "axios";

export const apiLocalhost = 'http://localhost:8080/api/v1/';
export const apiDomain = 'localhost:3000/api/v1/';
export const apiPort = '8080';
export const apiDomainPingPong = 'https://pingpong.id.vn/api/v1/';

export const api = axios.create({
  baseURL: apiDomainPingPong,
});

// api.interceptors.request.use((config) => {
//   const token = localStorage.getItem("jwtToken");
//   if (token) {
//     config.headers["Authorization"] = `Bearer ${token}`;
//   }
//   return config;
// });
