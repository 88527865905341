// ----------------------------------------------------------------------

export default function StaticDateRangePicker(theme) {
  return {
    MuiStaticDateRangePicker: {
      styleOverrides: {
        zIndex: 999999999
      }
    }
  };
}
