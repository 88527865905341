export const currencyFormat = (number) => {
  if (number === null || number === undefined) return "_";
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });
  return formatter.format(number);
};

export const fCurrency = (number) => {
  if (number === null || number === undefined) return "_";
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });
  return formatter.format(number);
};
export function fCurrencyVNDAndShorter(value) {
  // Check if the value is a number
  if (typeof value !== "number") {
    return null;
  }

  // Helper to format numbers without unnecessary decimal places
  const formatNumber = (num) => {
    return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
  };

  // Determine if the number is negative
  const isNegative = value < 0;

  // Use absolute value for formatting
  value = Math.abs(value);

  // Handle large numbers and format accordingly
  let result;
  if (value >= 1e9) {
    result = formatNumber(value / 1e9) + "B ₫"; // Billion VND
  } else if (value >= 1e6) {
    result = formatNumber(value / 1e6) + "M ₫"; // Million VND
  } else if (value >= 1e3) {
    result = formatNumber(value / 1e3) + "K ₫"; // Thousand VND
  } else {
    result = value.toLocaleString() + " ₫"; // Smaller values with commas
  }

  // Add negative sign back if the number was negative
  return isNegative ? "-" + result : result;
}
