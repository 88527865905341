import { Box, IconButton, styled, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Image from "../../components/Image";
import CarouselArrowIndex from "../../components/carousel/CarouselArrowIndex";
import noImageAvailableImg from "assets/images/No_Image_Available.jpg";
import { AddAPhoto, AddCircleOutline } from "@mui/icons-material";
import UploadMultipleFileDialog from "components/upload/UploadMultiFileDialog";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { replaceImagesForLine } from "api/line";
const RootStyle = styled("div")(({ theme }) => ({
  "& .slick-slide": {
    float: theme.direction === "rtl" ? "right" : "left",
    "&:focus": { outline: "none" },
  },
}));
const THUMB_SIZE = 64;

const LineDetailCarousel = ({ data, images }) => {
  const [openLightbox, setOpenLightbox] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [nav1, setNav1] = useState();

  const [nav2, setNav2] = useState();

  const slider1 = useRef(null);

  const slider2 = useRef(null);

  const imagesLightbox = images.map((_image) => _image);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((image) => image.url === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const settings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const settings2 = {
    rows: 1,
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: "0px",
    adaptiveHeight: true,

    slidesToShow: images.length > 4 ? 4 : images.length,
  };

  useEffect(() => {
    if (slider1.current) {
      setNav1(slider1.current);
    }
    if (slider2.current) {
      setNav2(slider2.current);
    }
  }, []);

  const handlePrevious = () => {
    slider2.current?.slickPrev();
  };

  const handleNext = () => {
    slider2.current?.slickNext();
  };

  const theme = useTheme();
  const hasNoImage = !images || images.length === 0;

  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleSaveImage = async (imageUrls) => {
    setOpen(!open);
    const lineId = data.bladeUnitID.toString();
    const newImages = imageUrls.map((url) => ({ url, objId: lineId }));
    await replaceImagesForLine(lineId, [...images, ...newImages]);
    toast.success("Upload success");
    queryClient.invalidateQueries({ queryKey: ["line"] });
  };

  return (
    <RootStyle>
      <Box sx={{ p: 1 }}>
        <Box sx={{ zIndex: 0, borderRadius: 2, overflow: "hidden", position: "relative" }}>
          {hasNoImage && (
            <div
              onMouseEnter={() => {
                setHover(true);
                console.log("hover");
              }}
              onMouseLeave={() => setHover(false)}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  display: !hover ? "none" : "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography variant="body1" sx={{ color: "white", fontSize: "22px", fontWeight: "light" }}>
                  Add an image
                </Typography>
                <IconButton size="large" onClick={() => setOpen(true)}>
                  <AddAPhoto sx={{ color: "white" }} />
                </IconButton>
              </Box>
              <Image src={noImageAvailableImg} />
            </div>
          )}

          <Slider {...settings1} asNavFor={nav2} ref={slider1}>
            {images.map(({ url }) => (
              <Image
                key={url}
                alt="large image"
                src={url}
                ratio="1/1"
                onClick={() => handleOpenLightbox(url)}
                sx={{ cursor: "zoom-in" }}
              />
            ))}
          </Slider>
          {!hasNoImage && (
            <CarouselArrowIndex
              index={currentIndex}
              total={images.length}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
        </Box>
      </Box>

      {!hasNoImage && (
        <Box
          sx={{
            my: 1,
            // display: "flex",
            ...(images.length === 1 && { display: "flex" }),
            mx: "auto",
            "& .slick-current .isActive": { opacity: 1 },
            ...(images.length === 1 && { maxWidth: THUMB_SIZE * 1 + 16 }),
            ...(images.length === 2 && { maxWidth: THUMB_SIZE * 2 + 32 }),
            ...(images.length === 3 && { maxWidth: THUMB_SIZE * 3 + 48 }),
            ...(images.length === 4 && { maxWidth: THUMB_SIZE * 3 + 48 }),
            ...(images.length >= 5 && { maxWidth: THUMB_SIZE * 6 }),
            ...(images.length > 2 && {
              position: "relative",
              // '&:before, &:after': {
              //   top: 0,
              //   zIndex: 9,
              //   content: "''",
              //   height: '100%',
              //   position: 'absolute',
              //   width: (THUMB_SIZE * 2) / 3,
              //   backgroundImage: (theme) =>
              //     `linear-gradient(to left, ${alpha(theme.palette.background.paper, 0)} 0%, ${
              //       theme.palette.background.paper
              //     } 100%)`,
              // },
              "&:after": { right: 0, transform: "scaleX(-1)" },
            }),
          }}
        >
          <Slider {...settings2} asNavFor={nav1} ref={slider2}>
            {images.map(({ url }, index) => (
              <Box key={url} sx={{ px: 0.75 }}>
                <Image
                  disabledEffect
                  alt="thumb image"
                  src={url}
                  sx={{
                    width: THUMB_SIZE,
                    height: THUMB_SIZE,
                    borderRadius: 1.5,
                    cursor: "pointer",
                    opacity: index === currentIndex ? 1 : 0.64,
                    ...(currentIndex === index && {
                      border: (theme) => `solid 3px ${theme.palette.primary.main}`,
                    }),
                  }}
                />
              </Box>
            ))}
          </Slider>
          <Box sx={{ px: 0.75, position: images.length > 2 ? "absolute" : "static", top: 0, right: -THUMB_SIZE }}>
            <IconButton
              sx={{
                width: THUMB_SIZE,
                height: THUMB_SIZE,
                borderRadius: 1.5,
                backgroundColor: "white",
                border: "1px solid #E0E0E0",
              }}
              onClick={() => setOpen(true)}
            >
              <AddAPhoto />
            </IconButton>
          </Box>
        </Box>
      )}
      <UploadMultipleFileDialog
        currentValues={images}
        open={open}
        onClose={() => setOpen(false)}
        setOpen={setOpen}
        onSave={handleSaveImage}
      />
    </RootStyle>
  );
};

export default LineDetailCarousel;
