import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Chip, emphasize, FormControl, InputLabel, MenuItem, Pagination, styled, Select } from "@mui/material/";
import { Home, ExpandMore } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";

import { MyContext } from "../../App";
import useAuth from "../../hooks/useAuth";
import { getSearchBlades } from "api/blade";
import { getBrands } from "api/brand";

import { FaEye, FaPencilAlt, FaPlus } from "react-icons/fa";
import PopupAdd from "./components/PopupAdd";

// Breadcrum code
const StyledBreadcrumb = styled(Chip)(({theme}) => {
    const backgroundColor =
        theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
})

const Blade = () => {

    // Check login
    // const navigate = useNavigate();
    const token = localStorage.getItem("jwtToken");
    // if (token == null || token == undefined) navigate("/login");
    const email = localStorage.getItem("email");

    const { isAuthenticated } = useAuth();

    const [brandVal, setBrandVal] = useState("");

    const context = useContext(MyContext);

    useEffect(()=>{
        // Hide sidebar
        context.setIsHideSidebarAndHeader(false);
        window.scrollTo(0,0);
    })

    const { data: brands } = useQuery({
        queryKey: ["brandList"],
        queryFn: async () => {
           return getBrands();
        },
    });

    const { data: blades, isLoading: isLoadingBlades } = useQuery({
        queryKey: ["blades", brandVal],
        queryFn: async () => {
            return await getSearchBlades({
                brandCD: brandVal
            });
        },
    });

    const brandData = brands?.data || [];
    const bladeData = blades?.data || [];

    const handleClickBrand = async (event) => {
        try {
            setBrandVal(event.target.value);
        } catch (error) {
            console.error(error);
        }
    };

    // Popup
    const [showModalPopupAdd, setShowModalPopupAdd] = useState(false);
    const openPopupAdd = () => {
        setShowModalPopupAdd(!showModalPopupAdd);
    }

    return <>
        <section className="right-content w-100">
            <div className="card shadow border-0 w-100 flex-row p-4">
                <h5 className="mb-0">Blade List</h5>
                <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
                    <StyledBreadcrumb
                        component="a"
                        href="#"
                        label="Dashboard"
                        icon={<Home fontSize="small"/>}
                    />
                    <StyledBreadcrumb
                        label="Products"
                        deleteIcon={<ExpandMore/>}
                    />
                </Breadcrumbs>
            </div>

            <div className="row dashboardBoxWrapperRow">
                <div className="col-md-8">
                    <div className="dashboardBoxWrapper d-flex">

                    </div>
                </div>

                <div className="col-md-4 pl-0">
                    <div className="box">

                    </div>
                </div>
            </div>

            <div className="card shadow border-0 p-3 mt-4">
                <div className="row cardFilters mt-3 mb-3">
                    <div className="col-md-2">
                        <div className="logoutBox" onClick={openPopupAdd}>
                            <Link to={"/blade-create"}>
                                <Button variant="contained" disabled={!isAuthenticated}>Tạo blade</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="offset-md-8 col-md-2">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">BRAND</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={brandVal}
                                    label="BRAND"
                                    onChange={handleClickBrand}
                                >
                                    <MenuItem value={''}>Tất cả</MenuItem>
                                    {brandData && brandData.length > 0 ? (
                                        brandData.map((item) => (
                                        <MenuItem key={item.brandCD} value={item.brandCD}>{item.brandName}</MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value={'XXX'}>NONE</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>

                <div className="table-responsive mt-3">
                    <table className="table table-bordered v-align">
                        <thead className="thead-dark">
                            <tr>
                                <th>UID</th>
                                <th style={{width:'300px'}}>PRODUCT</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>

                        <tbody>
                            {bladeData && bladeData.length > 0 ? (
                                bladeData.map((item) => (
                                <tr>
                                    <td>{item.seq}</td>
                                    <td>{item.bladeFullName}</td>
                                    <td>
                                        <div className="actions d-flex align-items-center">
                                            {/* <Link to={`/blade-detail/${item.seq}`}><Button className="secondary" color="secondary"><FaEye/></Button></Link> */}

                                            <Link to={`/blade-detail/${item.bladeCD}`}><Button className="secondary" color="secondary"><FaEye/></Button></Link>
                                            <Button className="success" color="success"><FaPencilAlt/></Button>

                                            {item.periodCnt > item.countRegister ? (
                                                <Button className="success" color="success" onClick={openPopupAdd}><FaPlus /></Button>
                                            ) : (
                                                ''
                                            )}

                                            {/* <Button className="error" color="error"><MdDelete/></Button> */}
                                        </div>
                                    </td>
                                </tr>
                                ))
                            ) : (
                                <tr className="bladeBox">
                                    <td colSpan="8">Không có sản phẩm</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {showModalPopupAdd &&
                        <PopupAdd
                            onClose={() => setShowModalPopupAdd(false)}
                            showModalPopupAdd={showModalPopupAdd}
                            token={token}
                            email={email}
                        />
                    }

                    <div className="d-flex tableFooter">
                        <p>Showing <b>12</b> of <b>60</b> results</p>
                        <Pagination count={10} color="primary" className="pagination" showFirstButton showLastButton />
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Blade;
