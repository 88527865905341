import { Box, Popper } from "@mui/material";
import { useId, useRef, useState } from "react";

const HoverImage = ({ image }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const id = useId();

  const handlePopoverOpen = (event) => {
    setAnchorEl(boxRef.current);
  };

  const boxRef = useRef(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
    // setTimeout(() => {
    //   setAnchorEl(null);
    // }, 500);
  };
  return (
    <div>
      <Box
        ref={boxRef}
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        sx={{
          width: 24,
          height: 24,
          border: "1px solid #ccc",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <img
          src={image}
          alt="image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "50%",
          }}
        />
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableRestoreFocus
        sx={{
          zIndex: 100,
        }}
      >
        <Box
          sx={{
            width: 200,
            height: 200,
            border: "1px solid #ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "white",
            marginBottom: "10px",
          }}
        >
          <img
            src={image}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "50%",
            }}
            alt="image"
          />
        </Box>
      </Popper>
    </div>
  );
};

export default HoverImage;
