import { Box, Button, Typography, Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from "@mui/material/";
import { Close } from "@mui/icons-material";

const UnregistNodePopup = ({ onClose, handleUnregist, open }) => {

    return (
        <Dialog open={open} maxWidth="lg">
            <DialogTitle>
                Hủy đăng ký
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Bạn muốn hủy đăng ký?
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Huỷ
                </Button>
                <Button variant="contained" onClick={handleUnregist}>
                    Xác nhận
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnregistNodePopup;
