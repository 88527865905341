import { api } from ".";

export const verifyGoogleToken = async (token) => {
  console.log("TOKEN", token);
  const response = await api.post("public/google/verify", {
    credential: token,
  });
  return response;
};

export const logout = async () => {
  const response = await api.post("public/logout");
  const data = await response.json();
  return data;
};
