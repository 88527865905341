import { isString } from "lodash";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Link,
  useTheme,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import bytesToSize from "../../utils/bytesToSize";
// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
  [theme.breakpoints.up("md")]: { textAlign: "left", flexDirection: "row" },
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object,
  hideRemoveAll: PropTypes.bool,
};

export default function UploadMultiFile({
  currentValues = [],
  error,
  showPreview = false,
  files = [],
  onRemove,
  onRemoveAll,
  sx,
  hideRemoveAll = false,
  ...other
}) {
  const hasFile = (files && files.length > 0) || (currentValues && currentValues.length > 0);

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: "error.light",
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {size}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%", p: 1, ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          // border: `1px dashed ${isDragActive ? "primary.main" : "grey.500"}`,
          ...(isDragActive && { opacity: 0.72, borderColor: "primary.main" }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
            borderStyle: "dashed",
          }),
        }}
      >
        <input {...getInputProps()} />
        {/* <UploadIllustration sx={{ width: 220 }} /> */}
        <Stack direction={"row"} alignItems={"center"}>
          <Avatar>
            <FileUploadOutlinedIcon />
          </Avatar>
          <Box sx={{ p: 1, ml: { md: 2 } }}>
            <Typography gutterBottom variant="h5">
              Chọn hoặc kéo tập tin vào đây
            </Typography>
          </Box>
        </Stack>
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}

      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        {[...(files||[]), ...currentValues].map((file, index) => {
          let name, size, preview;
          if (file.url) {
            // Đây là một hình ảnh từ currentValues
            name = `Image ${index + 1}`;
            size = "";
            preview = file.url;
          } else if (file.tenFile) {
            name = file.tenFile;
            size = file.Size;
            preview = file.preview;
          } else {
            name = file.name;
            size = bytesToSize(file.size);
            preview = file.preview;
          }

          const key = isString(file) ? file : name;

          if (showPreview) {
            return (
              <ListItem
                key={key}
                sx={{
                  p: 0,
                  m: 0.5,
                  width: 80,
                  height: 80,
                  borderRadius: 1.5,
                  overflow: "hidden",
                  position: "relative",
                  display: "inline-flex",
                  border: file.isOld ? "2px solid #E0E0E0" : `2px solid ${theme.palette.primary.main}`,
                }}
              >
                <Paper
                  variant="outlined"
                  component="img"
                  src={isString(file) ? file : preview}
                  sx={{ width: "100%", height: "100%", objectFit: "cover", position: "absolute" }}
                />
                <Box sx={{ top: 6, right: 6, position: "absolute" }}>
                  <IconButton
                    size="small"
                    onClick={() => onRemove(file)}
                    sx={{
                      p: "2px",
                      color: "common.white",
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                      "&:hover": {
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </ListItem>
            );
          }

          return (
            <ListItem
              key={key}
              sx={{
                my: 1,
                py: 0.75,
                px: 2,
                borderRadius: 1,
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                bgcolor: "background.paper",
              }}
            >
              <ListItemIcon>
                <TextSnippetOutlinedIcon color="info" />
              </ListItemIcon>
              <ListItemText
                primary={isString(file) ? file : name}
                secondary={isString(file) ? "" : size}
                primaryTypographyProps={{
                  variant: "subtitle2",
                  href: isString(file) ? file : file?.urlFile,
                  target: "_blank",
                  component: "a",
                }}
                secondaryTypographyProps={{ variant: "caption" }}
              />

              <ListItemSecondaryAction>
                <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>

      {hasFile && !hideRemoveAll && (
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={onRemoveAll} variant="outlined" sx={{ mr: 1.5 }} color="error">
            remove all
          </Button>
        </Stack>
      )}
    </Box>
  );
}
