import { createContext, useEffect, useState } from "react";
import "./assets/css/bootstrap.min.css";
// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.css";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { AppRouter } from "./router";
import AppProvider from "./providers/AppProvider";
import ThemeConfig from "./theme";

const MyContext = createContext({
  isToggleSidebar: false,
  setIsToggleSidebar: () => {},
  isLogin: false,
  setIsLogin: () => {},
  isHideSidebarAndHeader: false,
  setIsHideSidebarAndHeader: () => {},
  themeMode: true,
  setThemeMode: () => {},
});

function App() {
  // Check login
  const token = localStorage.getItem("jwtToken");
  // if (token == null || token == undefined) navigate("/login");

  const [isLogin, setIsLogin] = useState(false); // setIsLogin if localStorage have token
  const [isToggleSidebar, setIsToggleSidebar] = useState(false);
  const [isHideSidebarAndHeader, setIsHideSidebarAndHeader] = useState(false);
  const [themeMode, setThemeMode] = useState(true);

  useEffect(() => {
    if (themeMode === true) {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
      localStorage.setItem("themeMode", "light");
    } else {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
      localStorage.setItem("themeMode", "dark");
    }
    if (token != null || token != undefined) {
      setIsLogin(true);
    }
  }, [themeMode]);

  // const location = useLocation();

  // useEffect(()=>{
  //   alert(location.pathname);
  // },[location])

  const values = {
    isToggleSidebar,
    setIsToggleSidebar,
    isLogin,
    setIsLogin,
    isHideSidebarAndHeader,
    setIsHideSidebarAndHeader,
    themeMode,
    setThemeMode,
  };

  useEffect(() => {
    // alert(isToggleSidebar)
  }, [isToggleSidebar]);

  return (
    <AppProvider>
      <ThemeConfig>
        <MyContext.Provider value={values}>
          <AppRouter />
        </MyContext.Provider>
      </ThemeConfig>
    </AppProvider>
  );
}

export default App;
export { MyContext };
