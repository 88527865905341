import { api } from ".";

export const uploadFile = async ({ file }) => {
  const formData = new FormData();
  formData.append("file", file);
  console.log(file);
  const response = await api.post("public/upload", formData , {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const uploadFiles = async ({ files }) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  const response = await api.post("public/file/uploads", formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
    return response;
  };
