import { useState } from "react";
import useAuth from "./useAuth";
import { api } from "api";
import { unregist } from "../api/line";
import { toast } from "react-toastify";

export const useHandleUnregisterLineProgressNode = ({ onComplete = () => {} }) => {
  const { user } = useAuth();
  const [showModalPopupUnregist, setShowModalPopupUnregist] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const unregistNode = (data) => {
    setShowModalPopupUnregist(true);
    setCurrentNode(data);
  };

  const handleUnregist = async (event) => {
    try {
      const responsePromise = unregist({
        email: user.email,
        bladeUnitID: currentNode.id,
        seq: currentNode.seq,
        token: "ABCXYZ",
      });

      const response = await toast.promise(responsePromise, {
        pending: "Promise is pending",
        success: "Hủy đăng ký thành công",
        error: "Hủy đăng ký thất bại",
      });

      onComplete({
        response,
        currentNode,
      });

      setShowModalPopupUnregist(false);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    showModalPopupUnregist,
    setShowModalPopupUnregist,
    unregistNode,
    handleUnregist,
    currentNode,
    setCurrentNode,
  };
};
