import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { CgListTree } from "react-icons/cg";
import { FaUserCircle } from "react-icons/fa";
import { FaAngleRight, FaGripVertical } from "react-icons/fa6";
import { IoMdLogIn, IoMdLogOut } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { TbBrandCarbon } from "react-icons/tb";
import useAuth from "../../hooks/useAuth";
import { useTheme } from "@mui/material";
const menuItems = [
  {
    id: 1,
    name: "Dashboard",
    path: "/",
    icon: <MdDashboard />,
  },
  {
    id: 2,
    name: "Brand 상표",
    path: "/brand",
    icon: <TbBrandCarbon />,
    children: [
      {
        id: 1,
        name: "Danh sách",
        path: "/brand-list",
      },
    ],
  },
  {
    id: 3,
    name: "Blade",
    path: "/",
    icon: <FaGripVertical />,
    children: [
      {
        id: 1,
        name: "Danh sách",
        path: "/blade-list",
      },
    ],
  },
  {
    id: 4,
    name: "Line",
    path: "/",
    icon: <CgListTree />,
    children: [
      {
        id: 1,
        name: "Danh sách",
        path: "/line-list",
      },
    ],
  },
  {
    id: 5,
    name: "Transaction",
    path: "/",
    icon: <GrTransaction />,
    children: [
      {
        id: 1,
        name: "Report",
        path: "/transaction-report",
      },
    ],
  },
  {
    id: 6,
    name: "User",
    path: "/profile",
    icon: <FaUserCircle />,
  },
];

const NavItem = ({ label, icon, path }) => {
  return (
    <li>
      <Link to={path}>
        <Button className={`w-100`}>
          <span className="icon">{icon}</span>
          {label}
        </Button>
      </Link>
    </li>
  );
};

const SubmenuItem = ({ label, icon, path, items }) => {
  const location = useLocation();

  const isOpenSubmenu = (index) => {
    setIsToggleSubmenu(!isToggleSubmenu);
  };
  const isActive = location.pathname === path || items.some((item) => item.path === location.pathname);
  const [isToggleSubmenu, setIsToggleSubmenu] = useState(isActive);
  const theme = useTheme();

  return (
    <li>
      <Button className={`w-100 ${isActive ? "active" : ""}`} onClick={() => isOpenSubmenu(2)}>
        <span className="icon">{icon}</span>
        {label}
        <span
          className="arrow"
          style={{
            transform: isToggleSubmenu ? "rotate(90deg)" : "rotate(0deg)",
          }}
        >
          <FaAngleRight />
        </span>
      </Button>
      <div className={`submenuWrapper ${isToggleSubmenu === true ? "collapsed" : "collapse"}`}>
        <ul className="submenu">
          {items.map((item) => (
            <li key={item.id}>
              <Link
                to={item.path}
                style={{
                  color: location.pathname === item.path ? theme.palette.primary.main : "inherit",
                }}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

const Sidebar = () => {
  const { logout, isAuthenticated } = useAuth();

  const handleLogout = async (event) => {
    logout();
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="sidebar">
        <ul>
          {menuItems.map((item) => {
            if (!item.children) {
              return <NavItem key={item.id} label={item.name} icon={item.icon} path={item.path} />;
            } else {
              return (
                <SubmenuItem key={item.id} label={item.name} icon={item.icon} path={item.path} items={item.children} />
              );
            }
          })}
        </ul>

        <div className="logoutWrapper">
          {isAuthenticated ? (
            <div className="logoutBox" onClick={handleLogout}>
              <Button variant="contained">
                <IoMdLogOut />
                Logout
              </Button>
            </div>
          ) : (
            <div
              className="logoutBox"
              onClick={() => {
                navigate("/login");
              }}
            >
              <Button variant="contained">
                <IoMdLogIn />
                Login
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
