import { Card, CardContent, CardHeader, Grid, styled, Typography } from "@mui/material";
import { periods } from "../../constants";
import { fCurrency } from "../../utils";

const LineDetailsSummary = ({ data }) => {
  // const {}
  const datas = [
    {
      name: "brand",
      label: "Brand",
    },
    {
      name: "bladeUnitID",
      label: "Blade",
    },
    {
      name: "initPrice",
      label: "Init Price",
      getValue: (value) => fCurrency(value),
    },
    {
      name: "endPrice",
      label: "End Price",
      getValue: (value) => fCurrency(value),
    },
    {
      name: "depreciation",
      label: "Depreciation",
      getValue: (value) => fCurrency(value),
    },
    {
      name: "deposit",
      label: "Deposit",
      getValue: (value) => fCurrency(value),
    },
    {
      name: "fee",
      label: "Fee",
      getValue: (value) => fCurrency(value),
    },
    {
      name: "period",
      label: "Period",
      getValue: (value) => periods.find((x) => x.value === value).label,
    },
    {
      name: "periodCnt",
      label: "Period count",
    },
  ];

  return (
    <Grid container>
      {datas.map(({ name, label, getValue = (value) => value }, index) => (
        <Grid key={name} item container direction={"row"}>
          <Grid item xs={6} key={index}>
            <Typography variant="subtitle1" fontWeight={500} color="grey.700" sx={{ mb: 3 }}>
              {label}
            </Typography>
          </Grid>
          <Grid item xs={6} key={index}>
            <Typography variant="h5" color="text.secondary" fontWeight={500} sx={{ mb: 3 }}>
              {getValue(data[name])}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default LineDetailsSummary;
