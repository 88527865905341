import { useState } from "react";
import useAuth from "./useAuth";
import { api } from "api";
import { changeStatus } from "api/line";
import { toast } from "react-toastify";

export const useHandleRemoveLineProgressNode = ({ onComplete = () => { } }) => {
    const { user } = useAuth();
    const [showModalPopupRemoveLine, setShowModalPopupRemoveLine] = useState(false);
    const [currentNode, setCurrentNode] = useState(null);
    const removeLineNode = (data) => {
        setShowModalPopupRemoveLine(true);
        setCurrentNode(data);
    };

    const handleRemoveLine = async (event) => {
        try {
            const responsePromise = changeStatus({
                bladeUnitID: currentNode.bladeUnitID,
                lineID: currentNode.lineId,
            });

            const response = await toast.promise(responsePromise, {
                pending: "Promise is pending",
                success: "Hủy line thành công",
                error: "Hủy line thất bại",
            });

            onComplete({
                response,
                currentNode,
            });

            setShowModalPopupRemoveLine(false);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        showModalPopupRemoveLine,
        setShowModalPopupRemoveLine,
        removeLineNode,
        handleRemoveLine,
        currentNode,
        setCurrentNode,
    };
};
