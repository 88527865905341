import moment from "moment";
import { PERIODS } from "../constants";

export const addPeriod = ({ date, period, periodInex }) => {
  switch (period) {
    case PERIODS["2W"]:
      return moment(date).add(periodInex * 2, "weeks");
    case PERIODS["1M"]:
      return moment(date).add(periodInex * 1, "months");
    default:
      return moment(date);
  }
};
