import PropTypes from "prop-types";

// material
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import componentsOverride from "./overrides";

import palette from "./palette";
import shadows, { customShadows } from "./shadows";
import typography from "./typography";
import shape from "./shape";
// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default function ThemeConfig({ children }) {
  const theme = createTheme({
    palette: { ...palette.light, mode: "light" },
    shadows: shadows.light,
    customShadows: customShadows.light,
    typography: typography,
    shape: shape,
  });
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
