import { api } from ".";

export const getBlades = async () => {
  return api.get("/public/blade/list");
};

export const getSearchBlades = async (params) => {
  const response = await api.post("public/blade/search",{
    ...params
  });
  return response;
};

export const getBladeByCode = async (bladeCD) => {
  if (!bladeCD) return null;
  return api.get(`/public/blade/detail/${bladeCD}`);
};

export const getBladeUnitByBladeCD = async (bladeCD) => {
  if (!bladeCD) return null;
  return api.get(`/public/blade/${bladeCD}/bladeUnit`);
};
