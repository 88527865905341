// ----------------------------------------------------------------------

export default function Container(theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down("md")]: {
            px: 0
          }
        }
      }
    }
  };
}
