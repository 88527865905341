import { useFormContext, Controller } from "react-hook-form";
import { TextField as MuiTextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import e from "cors";

const RhfTextField = ({ name, label, type = "text", disabled, InputLabelProps, startAdornment, ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const defaultInputLabelProps = {
          shrink: (field.value !== undefined && field.value !== null && field.value !== "") || startAdornment,
        };
        return (
          <MuiTextField
            {...field}
            {...rest}
            disabled={disabled}
            type={type}
            label={label}
            error={Boolean(error)}
            helperText={error?.message}
            fullWidth
            multiline={type == "text"}
            InputLabelProps={{ ...defaultInputLabelProps, ...InputLabelProps }}
            InputProps={{ startAdornment }}
          />
        );
      }}
    />
  );
};

const RhfNumber = ({ name, label, type = "text", disabled, InputLabelProps, startAdornment,endAdornment, ...rest }) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const defaultInputLabelProps = {
          shrink: (field.value !== undefined && field.value !== null && field.value !== "") || startAdornment,
        };
        return (
          <NumericFormat
            customInput={MuiTextField}
            {...field}
            {...rest}
            disabled={disabled}
            onChange={(...e)=>{
              let formattedValue = e[0].target.value;
              let numberValue = parseFloat(formattedValue.replace(/,/g, ""));
              setValue(name, numberValue, { shouldDirty: true, shouldTouch: true, shouldValidate: true })
            }}
            type={type}
            label={label}
            error={Boolean(error)}
            helperText={error?.message}
            fullWidth
            multiline={type == "text"}
            InputLabelProps={{ ...defaultInputLabelProps, ...InputLabelProps }}
            InputProps={{ startAdornment, endAdornment }}
          />
        );
      }}
    />
  );
};

export { RhfNumber };
export default RhfTextField;
