import { api } from ".";

export const createLine = async ({
  bladeUnitID,
  email,
  periodCnt,
  period,
  initPrice,
  deposit,
  depreciation,
  fee,
  endPrice,
  bladeCD,
  // startDate,
  lineReq,
  registType,
}) => {
  const response = await api.post("public/line/insert", {
    // bladeUnitID,
    email,
    periodCnt,
    period,
    initPrice,
    deposit,
    depreciation,
    fee,
    endPrice,
    bladeCD,
    // id: Math.random().toString(36).substr(2, 9),
    bladeUnitID: bladeCD,
    // startDate,
    lineReq,
    registType,
  });
  return response;
};

export const createLineFormData = async (formData) => {
  const response = await api.post("public/line/insert", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
}; // console.log(formData.getAll("images"))

export const getLines = async (params) => {
  const response = await api.post("public/line/list", {
    ...params,
  });
  return response;
};

export const getLinesSearch = async (params) => {
  const response = await api.post("public/line/search", {
    ...params,
  });
  return response;
};

export const getLineDetail = async (lineID) => {
  const response = await api.get(`public/line/${lineID}`);
  return response;
};

export const register = async (params) => {
  const response = await api.post(`public/line/register`, params);
  return response;
};

export const changeStatus = async (params) => {
  const response = await api.post(`public/line/change-status`, params);
  return response;
};

export const unregist = async (params) => {
  const response = await api.post(`public/line/unregist`, params);
  return response;
};
export const replaceImagesForLine = async (lineID, images) => {
  const response = await api.put(`public/line/${lineID}/images`, {
    images,
  });
  return response;
};

export const replaceImagesForLineProgress = async (lineID, images) => {
  const response = await api.post(`public/line/line-progress/${lineID}/images`, {
    images,
  });
  return response;
};

export const updateCommentForLineProgress = async (lpId, comment) => {
  const response = await api.post(`public/line/line-progress/${lpId}/comment`, {
    comment,
  });
  return response;
};
