import { api } from ".";

export const getListCode = async () => {
  const response = await api.get("public/code/list");
  return response;
};

export const searchCodeByCdName = async (cdName) => {
  const response = await api.get("public/code/search", {
    params: { cdName },
  });
  return response;
};

export const getCodesByStandardEnglishId = async (standardEnglishId) => {
  const response = await api.get(`public/code/standard-english/${standardEnglishId}`);
  return response;
};
