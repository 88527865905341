import { TablePagination, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";

TablePaginationCustom.propsType = {
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
};
function defaultLabelDisplayedRows({ from, to, count }) {
  return `${from}–${to} của ${count !== -1 ? count : `more than ${to}`}`;
}
function TablePaginationCustom({ count, rowsPerPage, page, onPageChange, onRowsPerPageChange, pageBase = 1, ...rest }) {
  const theme = useTheme();
  const matchsDownMD = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <TablePagination
      {...rest}
      rowsPerPageOptions={[5, 10, 25, 100, 1000]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page - pageBase}
      onPageChange={(event, newPage) => {
        onPageChange(event, newPage + pageBase);
      }}
      onRowsPerPageChange={onRowsPerPageChange}
      showFirstButton={true}
      showLastButton={true}
      labelRowsPerPage={matchsDownMD ? "Số hàng" : "Số bản ghi trên trang:"}
      labelDisplayedRows={defaultLabelDisplayedRows}
    />
  );
}
export default TablePaginationCustom;
